* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;	
}


.App{
	overflow-x: hidden;
}


html{
	scroll-behavior: smooth;
}


body {
	font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
	
