.general{
    background-color: #000000;
    font-family: inherit;
    height: 100vh;
    opacity: 0.9;
    padding-top: 3.5%;
    overflow-y: hidden;
    text-align: center;
}

.general h6 {
  text-align: center;
  margin-bottom: 2px;
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
  font-weight: 100;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  text-shadow: 2px 2px 5px #888888;
  /* animation: bold_ani 3s; */
}


.go_back{
  padding: .8em 1.2em .8em 1.2em;
  margin-top: 1.5rem;  
  margin-bottom: 5% !important;
  background-color: #fff;
  color: black;
  border: none;
  border-radius: 1px;
  letter-spacing: 1.75px;
  animation: button-animation 1.5s ease;
  cursor: pointer;
}

.go_back:hover{
  box-shadow: 1px 1px 15px 1px #888888;
  text-decoration: none;
  
  color: black;
}



.button_div{
    margin-top: 1%;
}

@media screen and (max-width: 768px)  and (orientation : portrait){
  .general {
    overflow-y: scroll;
  }
}


@media screen and (max-width: 768px)  and (orientation : landscape){
  .general {
    overflow-y: scroll !important;
  }
}