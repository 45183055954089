.footer{
    width: 100vw;
    height: 20%;
    background-color: black;
    
}

.theDiv{
    color: white;
    padding:20px 0px 0px 0px;
    display: flex;
    /* gap: 12.5px; */
    /* padding: 0px 0px 0px 0px; */
    justify-content: space-between;
    flex-direction: column;
    align-items: center;   
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif  !important;   
}

.phone{
    display: flex;
    flex-direction: row;
    margin: 5px auto;
    justify-content: space-between;
    align-items: center;
    gap: 13px;
    opacity: 0.9;
}

.phone i {
    font-size: 22px;
}

.bold{
    font-weight: 800;
}

.author{
    font-weight: 100 !important;
    font-size: .7rem;
    opacity: 0.7;
}




.a_href{
    color: white;
    font-size: 1.1rem;
    text-decoration: none;
}

.a_href:hover{
    color: white;
    text-decoration: none;
    opacity: 0.5;
    /* font-size: 0.8rem; */
}

.button_q_a_a{
    padding: .6em .8em .6em .8em;
    margin: .2rem 0rem .5rem 0rem;  
    background-color: #fff;
    color: black;
    border: none;
    border-radius: 1px;
    letter-spacing: 1.75px;
    animation: button-animation 1.5s ease;
}

.button_q_a_a:hover{
    box-shadow: 1px 1px 15px 1px #888888;
    text-decoration: none;
    color: black;
}



@media screen and (max-width: 768px){ 
    .bold{
        font-weight: 600;
        font-size: 1.2rem;
    }

    
    .theDiv{
        color: white;
        padding:20px 0px 0px 0px;
        display: flex;
        gap: 15px !important;
        /* padding: 0px 0px 0px 0px; */
        justify-content: space-between;
        flex-direction: column;
        align-items: center;  
        
    }

    .phone{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px !important;
        opacity: 0.9;
    }
    
    .a_href{
        color: white;
        font-size: 1.05rem;
        margin-right: 10px;
        text-decoration: none;
    }
}