.item{
    width: 60vw;
    background-color: #fff;
    margin: 0.75% auto auto auto;
    padding: 15px 15px 10px 15px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    cursor: pointer;
    /* border-radius: 5px; */
    /* animation: MoveUpDown  */
}




/* .animate-up{
    animation: animate-it-up 3s;
}

@keyframes animate-it-up {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} */

.item:hover{
    box-shadow: 0px 4px 20px rgba(255, 255, 255, 0.2);
}

.question{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
}

.question p{
    font-weight: lighter ;
    font-size: 1rem;
}

.button{
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 20px;
}

#icon {
    font-weight: bolder;
}


.answer{
    font-size: .95rem;
    opacity: 0.5;
    color: black;
    text-align: right;
    line-height: 10px;
    transition: all .5s ease-in-out;
    overflow: hidden;
    padding: 0 1em;
    direction: rtl;
    
}



.answer p {
    line-height: 20px;
}

@media screen and (max-width: 768px)  and (orientation : portrait){

    .question {
        font-size: 1.2rem !important;
    }

    .text_question{
        font-size: .9rem !important;
        font-weight: 200 !important;
    }

    .item{
        width: 90vw;
    }

    .answer{
        font-size: .9rem;
    }

    
}