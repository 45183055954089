.main{
    width: 50vw;
    margin: 5% auto 5% auto !important; 
}

.carousel .thumb {
    display: none !important;
}

.image_car{
    object-fit: cover;
    
}

.divider_2{
    background-color: #eee;
    height: 1px;
    margin: auto;
    width: 80%;
}


.divider_3{
    background-color: #eee;
    height: 1px;
    margin: auto auto 0% auto;
    width: 80%;
}

.tit {
    text-align: center;
    margin-bottom: 30px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(97, 97, 97);
}

.carousel-status{
    display: none !important;
}

@media screen and (max-width: 768px) {
    .main{
        width: 75vw;
        margin: 15% auto 15% auto !important;
        
    }       
}