.second-div{
    /* margin-top: 2%; */
    width: 80%;
    margin: 2% auto 3% auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}


.body{
    direction: rtl !important;
    opacity: 0.7;
    text-align: center;
}

.second-div h4 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 50px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(97, 97, 97);
}

.item{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    font-weight: 300;
    font-size: 1.5rem !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(97, 97, 97);
}

.flex-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: auto;   */
    gap: 50px;
    
}

.image{
    width: 200px;
    height: 200px;
    text-align: center;
    object-fit: cover;   
    margin-bottom: 5%;
}



.divider_3{
    background-color: #eee;
    height: 1px;
    margin: auto;
    width: 80%;
}


@media screen and (max-width: 768px){
    .flex-div {
         flex-direction: column;
         gap: 50px;
    }
    .item h1 {
        text-align: center;
    }

    .title {
        font-size: 1.2rem !important;
        font-weight: 200;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: rgb(97, 97, 97);
    }

    .item{
        gap: 10px;
        /* min-height: 45vh;
        max-height: 40vh;
        min-width: 80vw;
        max-width: 20vw; */
    }

    .image{
        width: 80px;
        height: 80px;
        text-align: center;
        object-fit: cover;   
        margin-bottom: -1%;
    }

    .body {
        font-size: .9rem;
    }

    /* #delivery{
        margin-bottom: 500px !important;
    } */
    
}