.second-div{
    /* margin-top: 2%; */
    width: 80%;
    margin: 0% auto 3.5% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px; 
}



.second-div h5 {
    text-align: center;
    margin-bottom: 2px;
    font-size: 15px;
    line-height: 20px;
    margin-top: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
}


.second-div h4 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 20px;
    margin-top: 50px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(97, 97, 97);
}

.second-div h3 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 50px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(97, 97, 97);
}

.para {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 3rem;
}

.divider{
    background-color: #eee;
    height: 1px;
    margin: .2rem 0rem 1.7rem 0rem;
}
/* 
.absu-div{
    position: absolute;
    right: 5%;
    top: 0%;
    color: white;
} */

.inner-div{
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    align-items: center;
}


.space{
    margin-top: 1px;
}

.image{
    transition: 0.6s;
    height: auto;
    width: 100%;
}

.container{
    margin-top: 10px;
}

.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000000;
}

.text{
    color: rgb(255, 255, 255) !important;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.container .overlay {
    opacity: 0.7;
    color: rgb(255, 255, 255);
}


.size{
    padding: .8rem 1.2rem .8rem 1.2rem;
    margin-top: 15px;
    margin-right: 5px;
	border: none;
    cursor: pointer;
	background-color: black;
	color: white;
    outline: none !important;
	font-size: 7px;
	letter-spacing: 3px;
	font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.mail_size{
    padding: .4rem .8rem .4rem .8rem;
    margin-top: 15px;
    /* margin-left: 50px; */
	border: none;
    outline: none !important;
    cursor: pointer;
	background-color: rgb(0, 0, 0);
	color: white;
	font-size: 7px;
	letter-spacing: 3px;
	font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
/* 
.size:hover{
    font-size: 15px;
} */

.the_real_size{
    font-size: 12px;
}

.container{
    position: relative;
}

.active{
    padding: 1.2rem 2.2rem 1.2rem 2.2rem;
}


.active_for_mail{
    padding: 1.2rem 1.1rem 1.2rem 1.1rem;
}



.button_order{
    padding: .8em 1.2em .8em 1.2em;
    /* margin-top: 1.5rem;   */
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255) !important;
    border-radius: 1px;
    font-size: 21px;
    text-decoration: none;
    letter-spacing: 1.75px;
    animation: button-animation 1.5s ease;
    cursor: pointer;
}

.button_order:hover{
    box-shadow: 1px 1px 15px 1px #888888;
    text-decoration: none;
    color: black;
}

.new-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 22px;
    text-align: center;
}

.new-div p {
    font-size: 15px;
}

@media screen and (max-width: 768px) {
    .new-div {
        padding-top: 20px;
    }
    .new-div p {
        font-size: 13px;
    }   
}
 
@keyframes button-order-animation {
    50%{
        box-shadow: 1px 1px 40px 1px #000000;
    }

}





@media screen and (max-width: 768px) and (orientation : portrait){
    .second-div{
        margin-bottom: 14%;
    }
} 



@media screen and (max-width: 800px)  and (orientation : landscape){
    .second-div{
        margin-bottom: 10%;
    }
} 
