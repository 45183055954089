.divider_3 {
    background-color: #eee;
    height: 1px;
    margin: auto auto 0% auto;
    width: 80%;
}

.main_visual_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0px;
}

.main_visual_div h1 {
    font-size: 5rem;
    font-weight: 400;
    line-height: 120px;
    animation: h1-animation 1.5s ease;
}

.main_conatiner h4 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 50px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(97, 97, 97);
}

.main_conatiner h5 {
    text-align: center;
    margin-bottom: 0px;
    font-size: 15px;
    line-height: 20px;
    margin-top: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
}

.button_order_ios {
    padding: .4em 0.8em .4em 0.8em;
    /* margin-top: 1.5rem;   */
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255) !important;
    border-radius: 1px;
    font-size: 21px;
    text-decoration: none;
    letter-spacing: 1.75px;
    animation: button-animation 1.5s ease;
    cursor: pointer;
    width: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.button_order_ios:hover {
    box-shadow: 1px 1px 15px 1px #888888;
    text-decoration: none;
    color: black;
}

.button_order_android {
    padding: .4em 0.8em .4em 0.8em;
    /* margin-top: 1.5rem;   */
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0) !important;
    border-radius: 1px;
    border: 1px solid black;
    font-size: 21px;
    text-decoration: none;
    letter-spacing: 1.75px;
    animation: button-animation 1.5s ease;
    cursor: pointer;
    width: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.button_order_android:hover {
    box-shadow: 1px 1px 15px 1px #888888;
    text-decoration: none;
    color: black;
}


.button_visualize_div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
}


@keyframes h1-animation {
    0% {
        opacity: 0;
        margin-left: 50%;
        
    }
    50% {
        opacity: 0.5;
        margin-left: -5%;
        
    }

    100%{
        opacity: 1;
        margin-left: 0%;
        
    }
}