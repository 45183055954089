.body{
  min-height: 60vh;
  width: 70vw;
  display: flex;
  margin: 5% auto 70px auto;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}


.about-section {
  background: url('../../static/images/justlines_kits_background.jpg') no-repeat left;
  background-size: 65%;
  opacity: 0.8;
  overflow: hidden;
  padding: 100px 0;
}

.inner-container{
  width: 55%;
  float: right;
  text-align: center;
  background-color: #fdfdfd;
  padding: 50px;
  box-shadow: 10px 10px 5px #aaaaaa;
  /* border: .2px solid  black ; */
}

.inner-container h1 {
  margin-bottom: 50px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(97, 97, 97);
  font-size: 17.5px;
  font-weight: 500 !important;
}

.text {
  font-size: 17.5px;
  color: black;
  direction: rtl;
  opacity: 0.8;
  line-height: 40px;
  text-align: justify;
  margin-bottom: 40px;

}

.skills{
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}

@media screen and (max-width: 768px ){
  .inner-container{
    padding: 40px;
    box-shadow: none;
  }
  .inner-container h1 {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 200;
  }
  
  .text {
    font-size: 15px;
    color: black;
    line-height: 20px;
    text-align: justify;
    margin-bottom: 40px;
  
  }
  
}

@media screen and (max-width: 1000px ){
  .inner-container{
    padding: 40px;
    box-shadow: none;
    
  }
  .inner-container h1 {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 200;
    
  }
  
  .text {
    font-size: 15px;
    color: black;
    line-height: 20px;
    text-align: justify;
    margin-bottom: 40px;
  
  }
  
}

.span_text{
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-shadow: 1px 2px 10px #9c9292;
}

@media screen and (max-width: 768px)  and (orientation : portrait){
  .body{
    min-height: 40vh;
    width: 90vw;
    display: flex;
    margin: 25px auto 25px auto;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
  }
  
  
  .about-section {
    background: url('../../static/images/justlines_kits_background.jpg') no-repeat left;
    background-size: 70%;
    overflow: hidden;
    padding: 15px 0px 10px 0px;
  }
  
  .inner-container{
    width: 75%;
    direction: rtl;
    text-align: center;
    /* font-size: 5rem; */
    float: right;
    background-color: #fdfdfd;
    padding: 15px;
    /* box-shadow: 10px 10px 5px #aaaaaa; */
  }

  .inner-container h1 {
    margin-bottom: 10px;
    font-weight: 100;
    font-size: 13px;
    /* font-weight: 500; */
  }
  
  .text {
    font-size: 10px;
    color: black;
    line-height: 15px;
    text-align: justify;
    margin-bottom: 20px;
  
  }

  
.skills{
  display: flex;
  justify-content: space-between;
  font-weight: 100;
  font-size: 10px;
  }
}


@media screen and (max-width: 768px)  and (orientation : landscape){
  .body{
    min-height: 40vh;
    width: 90vw;
    display: flex;
    margin: 0px auto 50px auto;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
  }
  
  
  .about-section {
    background: url('../../static/images/justlines_kits_background.jpg') no-repeat left;
    background-size: 60%;
    overflow: hidden;
    padding: 50px 0;
  }
  
  .inner-container{
    width: 75%;
    direction: rtl;
    text-align: center;
    float: right;
    background-color: #fdfdfd;
    padding: 50px;
    /* box-shadow: 10px 10px 5px #aaaaaa; */
  }

  .inner-container h1 {
    margin-bottom: 10px;
    font-weight: 100;
    font-size: 13px;
    /* font-weight: 500; */
  }
  
  .text {
    font-size: 12px;
    color: black;
    line-height: 15px;
    text-align: justify;
    margin-bottom: 20px;
  
  }

  
.skills{
  display: flex;
  justify-content: space-between;
  font-weight: 100;
  font-size: 10px;
  }
}