nav {
	height: 10.5vh !important;
	background: white;
	z-index: 9999;
	position: fixed;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-content: center;
}

.shadow { 
	box-shadow: 1px 5px 15px 1px #00000063;
	height: 8.5vh !important;
	transition: 0.5s;
}


@keyframes nav-animation {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.logo_image_regular{
    width: 50%;
    height: 40% !important;
	transition: 1s;
	margin: auto auto 100px auto !important;
}

.logo_image_greater{
	width: 35%;
    height: 40% !important;
	transition: 1s;
	margin: auto;
	margin: auto auto 100px auto !important;
}

.contact-button{
	padding: .8rem 1.2rem .8rem 1.2rem;
	border: none;
	background-color: black;
	color: white;
	font-size: 9px;
	letter-spacing: 3px;
	font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;	
	cursor: pointer;
	transition: .5s;
}

.contact-button:hover {
	opacity: 0.75;
}

.brand_title{
	position: absolute;
	left:  5%;
	top: 35%;
	z-index: 0;
	font-size: 30px;
	
}
.nav-links {
	display: flex;
	list-style: none;
	width:  50%;
	/*background-color: lightcoral;*/
	height: 100%;
	justify-content: center;
	align-items: center;
	margin: auto;
	overflow-y:initial;
}

.brand_button{
	position: absolute;
	right:  5%;
	top: 25% !important;
}

.nav-links li a {
	color:  rgb(97, 97, 97);
	text-decoration: none;
	font-size: 12px;
	margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
	margin: 10px;
	/* animation: nav-animation 3s */
}

.nav-links li::after{
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background-color: rgb(97, 97, 97);
    transition: 300ms;
}

.nav-links li:hover::after{
    width: 100%;
}


/* Bars to Close icon animation -BEGGINING */


.menu-btn {
	margin: 0rem 0px 5rem 0px  !important;
    position: relative;
    display: none;
    justify-content: space-evenly;
    align-items: center;
    width: 80px;
    height: 50px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    /* border: 3px solid #fff; */
  }
















  .menu-btn__burger {
    width: 35px;
    height: 1.5px;
    background: black;
    border-radius: 5px;
    /* box-shadow: 0 2px 5px rgba(255,101,47,.2); */
    transition: all .5s ease-in-out;
  }
  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 35px;
    height: 1.5px;
    background: black;
    border-radius: 5px;
    /* box-shadow: 0 2px 5px rgba(255,101,47,.2); */
    transition: all .5s ease-in-out;
  }
  .menu-btn__burger::before {
    transform: translateY(-11.5px);
    background-color: black;
  }
  .menu-btn__burger::after {
    transform: translateY(11.5px);
  }
  /* ANIMATION */
  .menu-btn.open .menu-btn__burger {
    transform: translateX(-50px);
	position: absolute;
    background: black;
    box-shadow: none;
  }
  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
    background: white;
  }
  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
    background: white;
  }


/* Bars to Close icon animation - THE END */

@media screen and (max-width: 768px){	

	.fade:not(.show) {
		opacity: 1;
	}
	.line {
		width: 30px;
		height: 3px;
		background: black;
		margin:  5px;
	}

	nav {
		position: relative;
        margin-bottom: 3%;
	}

    .menu-btn{
        display: flex;
		transition: none;
    }

	.hamburger {
		position: absolute;
		cursor: pointer;
		right: 1%;
		top:  55%;
		transform: translate(-5%, -50%);
		z-index: 3;
	}

	.hamburger_after_change {
		position: fixed;
		cursor: pointer;
		right: 1%;
		top:  6%;
		transform: translate(-5%, -50%);
		z-index: 3;
		transition: 0s;
	}
	.contact-button{
		display: none;
	}

	.nav-links ul li a {
		color:  white !important;
	}

	.nav-links {
		position: fixed;
		background: black; 
		height: 100vh;
		width: 100%;
		justify-content: space-evenly;
		flex-direction: column;
		overflow-y: hidden;
		/*display: none;*/
		clip-path: circle(100px at 90% -30%);
		-webkit-clip-path: circle(100px at 90% -30%);
		transition: all 0.5s ease-out;
		pointer-events: none;
        z-index: 2;
	}

	.brand_title{
		z-index: 0;
	}


	.nav-links.close {
		clip-path: circle(2000px at 90% -10%);
		-webkit-clip-path: circle(2000px at 90% -10%);
		transition: all 1s ease-out;
		pointer-events: all;

	}


	.nav-links.open {
		clip-path: circle(2000px at 90% -10%);
		-webkit-clip-path: circle(2000px at 90% -10%);
		transition: all 1s ease-out;
		pointer-events: all;

	}

	.landing {
		flex-direction: column;
	}

	.nav-links li {
		opacity: 0;
	}

    .nav-links li a {
        color: white !important;
    }

	.nav-links li a {
		font-size: 25px;
	}

	.nav-links li:nth-child(1){
		transition: all 0.5s ease 0.2s ;
	}

	.nav-links li:nth-child(2){
		transition: all 0.5s ease 0.4s ;
	}

	.nav-links li:nth-child(3){
		transition: all 0.5s ease 0.6s ;
	}

	li.fade{
		opacity: 0;
	}




}

@media screen and (max-width: 768px)  and (orientation : portrait){
    nav {
		height: 13vh !important;
		background: white;
		position: fixed;
		top: 0;
		width: 100%;
		/* margin-bottom: 5%; */
	}


	.logo_image{
		width: 50%;
		height: 100%;
	}

	.logo_image_regular{
		width: 45%;
		height: 40px !important;
		transition: 1s;
		margin: auto auto 100px auto !important;
	}
	
	.logo_image_greater{
		width: 30%;
		height: 27.5px !important;
		transition: 1s;
		margin: auto;
		margin: auto auto 100px auto !important;
	}
}


@media only screen and (min-device-width : 300px) and (max-device-width : 1024px) and (orientation : landscape) {
    nav {
		height: 26vh !important;
		background: white;
		position: fixed;
		top: 0;
		width: 100%;
		/* margin-bottom: 5%; */
	}

	.shadow { 
		box-shadow: 1px 5px 15px 1px #00000063;
		height: 16.5vh !important;
		transition: 0.5s;
	}

	.logo_image{
		width: 50%;
		height: 100%;
	}
	.logo_image_regular{
		width: 45%;
		height: 35px !important;
		transition: 1s;
		margin: auto auto 100px auto !important;
	}
	
	.logo_image_greater{
		width: 30%;
		height: 25px !important;
		transition: 1s;
		margin: auto;
		margin: auto auto 100px auto !important;
	}
}




    /* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
	nav {
		height: 16vh !important;
		background: white;
		position: fixed;
		top: 0;
		width: 100%;
		/* margin-bottom: 5%; */
	}
	.nav-links li a {
		font-size: .9rem;
	}
	.logo_image{
		width: 80%;
	}
	.contact-button{
		font-size: 10px !important;
	}
}

@media only screen  and (min-width : 1224px) {
	nav {
		height: 10vh;
		background: white;
		position: fixed;
		top: 0;
		width: 100%;
		margin-bottom: 5%;
	}
	.nav-links li a {
		font-size: 0.75rem;
	}

	.contact-button{
		font-size: 8px !important;
	}

}

/* Large screens ----------- */
@media only screen  and (min-width : 1824px) {
	nav {
		height: 10vh;
		background: white;
		position: fixed;
		top: 0;
		width: 100%;
		margin-bottom: 5%;
	}
	.nav-links li a {
		font-size: 1rem;
	}

	.contact-button{
		font-size: 12px !important;
	}

	.logo_image{
		width: 80%;
		height: 100%;
	}
}


.brand_button{
	position: absolute;
	right:  5%;
	top: 35%;
}



.contact-button{
	padding: .8rem 1.2rem .8rem 1.2rem;
	border: none;
	background-color: black;
	color: white;
	font-size: 9px;
	letter-spacing: 3px;
	font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
	
}






@media screen and (max-width: 768px)  and (orientation : landscape){
	nav {
		height: 22vh !important;
		background: white;
		position: fixed;
		top: 0;
		width: 100%;
		margin-bottom: 5%;
	}

	
	.shadow { 
		box-shadow: 1px 5px 15px 1px #00000063;
		height: 15vh !important;
		transition: 0.5s;
	}


	.logo_image{
		width: 70%;
		height: 45px !important;
	}
	.nav-links {
		clip-path: circle(10px at 90% -20%);
		-webkit-clip-path: circle(10px at 90% -20%);
	}
}


