.main-conatiner{
    /* margin-top: 2%; */
    width: 80%;
    margin: 0% auto 0% auto;
}

.main-conatiner h5 {
  text-align: center;
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}



.divider{
    background-color: #eee;
    height: 1px;
    margin: .1rem 0rem 1.7rem 0rem;
}

.main-conatiner h4 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 50px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(97, 97, 97);
}

/* @keyframes bold_ani { 
  0%{
    font-weight: 100;
  } 
  25% {
    font-weight: 600;
  }
  50%{
    font-weight: 900;
  }
  75%{
    font-weight: 600;
  }
  100% {
    font-weight: 100;
  }
} */

.main-conatiner h6 {
  text-align: center;
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 20px;
  margin-top: 10px;
  font-weight: 100;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  text-shadow: 2px 2px 5px #888888;
  /* animation: bold_ani 3s; */
}

  

  /* Grids --------------------------------------------*/
  
  .container {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 200px);
    margin: 0px auto 15px ;
    max-width: 90%;
    padding: 3rem 0;
  }


  
  /* Grid images --------------------------------------------*/
  
  .img {
    /* border-radius: 1rem; */
    /* border: 1px solid black; */
    box-shadow: 5px 5px 10px 5px #aaaaaa;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }


  .img2 {
    /* border-radius: 1rem; */
    /* border: 1px solid black; */
    box-shadow: 5px 5px 10px 5px #aaaaaa;;
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* background-size: 50px 550px !important; */
    /* background-color: #f4f4f4; */
    /* padding: 10px ; */
  }

  .img1{
    box-shadow: 5px 5px 10px 5px #aaaaaa;;
    height: 100%;
    width: 100%;
    object-fit: cover;

}


@media screen and (max-width: 768px){
  .img1 {
    height: 100% !important;
    width: 100% !important;
  }

  .img2{
    box-shadow: 5px 5px 10px 5px #aaaaaa;;
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding: 0px;
  }
}

  
  /* Grid items --------------------------------------------*/
 

  .item-4 {
    grid-row: 1/3;
    position: relative;
  }
  
  .item-5 {
    grid-row: 1/5;
    position: relative;
  }
  
  .item-6 {
    grid-row: 1/3;
    position: relative;
  }
  
  .item-7 {
    grid-row: 3/5;
    position: relative;
  }
  
  .item-8 {
    grid-row: 3/5;
    position: relative;
  }
  
  @keyframes opacity_animation_close {
    from { 
      opacity: 1;
    }

    to{ 
      opacity: 0;
     }
  }

  @keyframes opacity_animation_open {
    from { 
      opacity: 0;
    }

    to { 
      opacity: 1;
    }
  }

  /* .open {
    animation: opacity_animation_open 2s;
  }

  .close {
    animation: opacity_animation_close 2s;
  } */


.overlay5 {
    position: absolute;
    /* border-radius: 1rem; */
    background-image: url('../../static/images/before.jpg');
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: 5s ease;  
    z-index: 20;
    background-position: bottom;
    background-size: 100% 100%;
    animation: opacity_animation_open 1.2s;
}

.overlay6 {
    position: absolute;
    /* border-radius: 1rem; */
    background-image: url('../../static/images/before3.jpg');
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .5s ease;  
    z-index: 20;
    background-position: bottom;
    background-size: 100% 100%;
    animation: opacity_animation_open 1.2s;
}

.overlay7 {
    position: absolute;
    /* border-radius: 1rem; */
    background-image: url('../../static/images/before10.jpeg');
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .5s ease;  
    z-index: 20;
    background-position: bottom;
    background-size: 100% 100%;
    animation: opacity_animation_open 1.2s;
}

.overlay4 {
    position: absolute;
    /* border-radius: 1rem; */
    background-image: url('../../static/images/before15.jpg');
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .5s ease;  
    z-index: 20;
    background-position: bottom;
    background-size: 100% 100%;
    animation: opacity_animation_open 1.2s;
}

.overlay8 {
    position: absolute;
    /* border-radius: 1rem; */
    background-image: url('../../static/images/before12.jpg');
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .5s ease;  
    z-index: 20;
    background-position: bottom;
    background-size: 100% 100%;
    animation: opacity_animation_open 1.2s;
}


/* .item-4:hover .overlay4 {
    opacity: 1;
}

.item-5:hover .overlay5 {
    opacity: 1;
}
.item-6:hover .overlay6 {
    opacity: 1;
}
.item-7:hover .overlay7 {
    opacity: 1;
}
.item-8:hover .overlay8 {
    opacity: 1;
} */

.divider{
    background-color: #eee;
    height: 1px;
    margin: .2rem 0rem 1.7rem 0rem;
}

@media screen and (max-width: 768px){
  .main-container{
    width: 100vw !important;
  }
    .container{
      
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
      
    }
    /* .overlay5{
        background-image: url('../../static//images/after.jpg');
        background-position: bottom;
        background-size: 100% 100%;
    } */



    .item-4 {
        grid-row: 1/1;
        position: relative;
      }
      
      .item-8 {
        grid-row: 2/3;
        position: relative;
      }
      
      .item-5 {
        display: none;
      }
      
      .item-6 {
        grid-row: 2/2;
        position: relative;
      }
      
      .item-7 {
        grid-row: 1/2;
        position: relative;
        
      }
      

    
}

@media screen and (max-width: 768px)  and (orientation : landscape){
  .container{
    grid-template-rows: repeat(2, 2fr);
    grid-template-columns: repeat(2, 2fr); 
  }

      .item-4 {
        grid-row: 1/1;
        position: relative;
      }
      
      .item-8 {
        grid-row: 1/2;
        position: relative;
      }

      .item-5 {
        display: none;
      }
      
      .item-6 {
        grid-row: 2/3;
        position: relative;
      }
      
      .item-7 {
        grid-row: 2/3;
        position: relative;
      }
      
   
}