.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	left:40px;
	background-color:rgba(0,0,0);
	opacity: 0.75;
	color: white !important;
	border-radius:50px;
	text-align:center;
  	font-size:30px;
	/* box-shadow: 2px 2px 5px 3px #999; */
  	z-index:100;
	cursor: pointer;
	transition: 1s;
}

.float:hover, .float-2:hover{
	opacity: 1;
	box-shadow: 1px 1px 5px 5px rgb(219, 212, 212);
}




.display-none{
	display: none;

}


@keyframes fade-out { 
	0% {
		opacity: 1;
	}

	100%{
		opacity: 0;
		display: none;
	}
}


.float-2 {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 110px;
	left: 40px;
	border-radius:50px;
	text-align:center;
	background-color: rgba(0,0,0);
	opacity: 0.75;
  	font-size:30px;
	color: white !important;
	/* box-shadow: 2px 2px 5px 3px #999; */
  	z-index:100;
	cursor: pointer;
	transition: 2s;
}

.my-float{
	margin-top:16px;
}

.ani {
	animation: animationIcon 2s;
}

@keyframes animationIcon { 
	0% { 
		box-shadow: 2px 2px 5px 3px #999;
	}
	25%{
		box-shadow: 2px 2px 25px 3px #999;
	}	
	50%{
		box-shadow: 2px 2px 5px 3px #999;
	}
	75%{
		box-shadow: 2px 2px 25px 3px #999;
	}
	100%{
		box-shadow: 2px 2px 5px 3px #999;
	}
}


@media screen and (max-width: 768px){
	.float{
		position:fixed;
		width:50px;
		height:50px;
		bottom:40px;
		left:20px;
		background-color:rgba(0,0,0) !important;
		color:#fff;
		opacity: 0.5;
		border-radius:50px;
		text-align:center;
		font-size:30px;
		/* box-shadow: 2px 2px 5px 3px #999; */
		z-index:100;
		transition: 2s;
	}
/* 
	.float:hover .float-2:hover {
		opacity: 1;
	} */


	.my-float{
		margin-top:11px;
	}

	.float-2 {
		position: fixed;
		width: 50px;
		height: 50px;
		bottom: 95px;
		left: 20px;
		opacity: 0.5;
		border-radius:50px;
		text-align:center;
		background-color:rgba(0,0,0) !important;
		font-size:30px;
		color: white !important;
		/* box-shadow: 2px 2px 5px 3px #999; */
		z-index:100;
		cursor: pointer;
	}
}