.contact-button-1{
	padding: .8rem 1.2rem .8rem 1.2rem;
	border: none;
	background-color: black;
	color: white;
	font-size: 12px !important;
	letter-spacing: 3px;
	font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;	
	cursor: pointer;
	transition: .5s;
}

.contact-button-1:hover {
	opacity: 0.75;
}

.div_button_close_ad{
	text-align: center !important;
	padding: 10px 10px 10px 10px !important;
}

.heart_color{
	color: rgba(194, 5, 5, 0.85);
}