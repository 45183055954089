.header{
    width: 94vw;
    height: 95vh;
    margin: auto;
    background-color: rgb(0, 0, 0);
    color:rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0.9;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif   ;
    animation: start-animation 2.5s ease;
}

@keyframes start-animation {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.9;
    }
}


@keyframes h1-animation {
    0% {
        opacity: 0;
        margin-left: 50%;
        
    }
    50% {
        opacity: 0.5;
        margin-left: -5%;
        
    }

    100%{
        opacity: 1;
        margin-left: 0%;
        
    }
}


@keyframes para-animation {
    0% {
        opacity: 0;
        margin-right: 50%;
        
    }
    50% {
        opacity: 0.5;
        margin-right: -5%;
        
    }

    100%{
        opacity: 1;
        margin-right: 0%;
        
    }
}

@keyframes button-animation {
    0% {
        opacity: 0.5;
        
    }


    100%{
        opacity: 1;
        
    }
}


.second {
    margin-top: 100px
}

.header h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 50px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(97, 97, 97);
} 
 
.header p {
    line-height: 30px;
    animation: para-animation 1.5s ease;
} 

.button_learn_more{
    padding: .8em 1.2em .8em 1.2em;
    margin-top: 1.5rem;  
    background-color: #fff;
    color: black;
    border: none;
    border-radius: 1px;
    font-weight: 500 !important;
    text-decoration: none;
    letter-spacing: 1.75px;
    animation: button-animation 1.5s ease;
}

.button_learn_more:hover{
    box-shadow: 1px 1px 15px 1px #888888;
    text-decoration: none;
    color: black;
}

/* 
@media screen and (max-width: 600px) {
    .header h1 {
        font-size: 3.25rem !important;
    }

    .header p {
        font-size: .95rem !important;
    }

    .header button {
        font-size: .8rem !important;
    }
}

@media screen and (max-width: 768px) {
    .header h1 {
        font-size: 4rem;
    }

    .header p {
        font-size: 1.25rem;
    }

    .header button {
        font-size: 1.05rem;
    }
}

@media screen and (max-width: 1200px){
    .header h1 {
        font-size: 5.5rem;
    }

    .header p {
        font-size: 1.75rem;
    }

    .header button {
        font-size: 1.5rem;
    }
}


@media screen and (max-width: 992px){
    .header h1 {
        font-size: 4.5rem;
    }

    .header p {
        font-size: 1.5rem;
    }

    .header button {
        font-size: 1.2rem;
    }
} */



@media only screen and (min-device-width : 200px) and (max-device-width : 320px) {
    .header h1 {
        font-size: 1.2rem !important;
    }

    .header p {
        font-size: 0.5rem !important;
    }

    .header button {
        font-size: 0.55rem;
    }
}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .header h1 {
        font-size: 1.8rem;
    }

    .header p {
        font-size: .75rem;
    }

    .header button {
        font-size: 0.75rem;
    }
}
    
    /* Smartphones (landscape) ----------- */
    @media only screen and (min-width : 321px) {
        .header{
            margin-top: 30px;
        }

        .header h1 {
            font-size: 2.2rem;
        }
    
        .header p {
            font-size: .95rem;
        }
    
        .header button {
            font-size: 0.85rem;
        }
    }
    
    /* Smartphones (portrait) ----------- */
    @media only screen and (max-width : 320px) {
        .header h1 {
            font-size: 1.7rem;
        }
    
        .header p {
            font-size: .7rem;
        }
    
        .header button {
            font-size: 0.65rem;
        }
    }
    
    /* iPads (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        .header h1 {
            font-size: 4rem;
        }
    
        .header p {
            font-size: 1.5rem;
        }
    
        .header button {
            font-size: 0.95rem;
        }
    }

    @media screen and (max-width: 768px)  and (orientation : portrait){  
        
        .header{
            margin: 0px auto auto auto;
        }
        .header h1 {
            font-size: 2rem;
        }
    
        .header p {
            font-size: .75rem;
        }
    
        .header button {
            font-size: .65rem;
        }
    }
    
    /* iPads (landscape) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
        .header h1 {
            font-size: 4rem;
        }
    
        .header p {
            font-size: 1.5rem;
        }
    
        .header button {
            font-size: 0.95rem;
        }
    }
    
    /* iPads (portrait) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
        .header h1 {
            font-size: 3rem;
        }
    
        .header p {
            font-size: 1.5rem;
        }
    
        .header button {
            font-size: 0.95rem;
        }
    }
    /**********
    iPad 3
    **********/
    /* @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    
    }
    
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    
    } */
    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 1224px) {
        .header h1 {
            font-size: 3.5rem;
        }
    
        .header p {
            font-size: 1.2rem;
            line-height: 80px;
        }
    
        .header button {
            font-size: 1.2rem;
        }
    }
    
    /* Large screens ----------- */
    @media only screen  and (min-width : 1824px) {
        .header h1 {
            font-size: 6.5rem;
        }
    
        .header p {
            font-size: 2.5rem;
            line-height: 100px;
        }
    
        .header button {
            font-size: 1rem;
        }
    }
    